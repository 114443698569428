(function(){
'use strict';

facebookFundraiserCtrl.$inject = ["$scope", "scFundraisingPagesService", "scCampaignsService", "scBanner", "CHANNEL"];
angular.module('classy.frs').controller('facebookFundraiserCtrl', facebookFundraiserCtrl);

function facebookFundraiserCtrl($scope, scFundraisingPagesService, scCampaignsService, scBanner, CHANNEL) {
  this.$onInit = function () {
    var _this = this;

    $scope.loading = true;
    if (SC.organization.facebookChannel.status) {
      scFundraisingPagesService.active.getChannelFundraisingPage(CHANNEL.FB).then(function (response) {
        if (response && response.data[0]) {
          $scope.isFBPageCreated = !!response.data[0].external_fundraising_page_id;
          $scope.loading = false;
        } else {
          $scope.loading = false;
          $scope.isFBPageCreated = false;
        }
      });
      scCampaignsService.active.getCampaignChannel(CHANNEL.FB).then(function (resp) {
        _this.isCampaignFBEnabled = _.get(resp, '[0].status', false);
      }).catch(function () {
        scBanner('There was an error fetching the campaign channel status.', 'error');
      });
    }
  };
}
})();