(function(){
'use strict';

angular.module('classy.frs').directive('scSupporterDashboardTabs', scSupporterDashboardTabs);

function scSupporterDashboardTabs() {
  return {
    templateUrl: 'frs/shared/supporter/directives/scSupporterDashboardTabs/template',
    controller: ['$scope', '$state', 'scCampaignsService', 'scBanner', 'CHANNEL', 'scFundraisingPagesService', function ($scope, $state, scCampaignsService, scBanner, CHANNEL, scFundraisingPagesService) {
      $scope.isOrganizationFbEnabled = SC.organization.facebookChannel.status;
      if ($scope.isOrganizationFbEnabled && $state.includes('frs.fundraiser.dashboardindex.dashboard')) {
        scCampaignsService.active.getCampaignChannel(CHANNEL.FB).then(function (resp) {
          $scope.isCampaignFBEnabled = _.get(resp, '[0].status', false);
        }).catch(function () {
          scBanner('There was an error fetching the campaign channel status.', 'error');
        });
        scFundraisingPagesService.active.getChannelFundraisingPage(CHANNEL.FB).then(function (response) {
          $scope.isFBPageCreated = !!_.get(response, 'data[0].external_fundraising_page_id', false);
        });
      }
    }],
    link: function link() {}
  };
}
})();