(function(){
'use strict';

scDashboardNotification.$inject = ["$timeout"];
angular.module('classy.frs').directive('scDashboardNotification', scDashboardNotification);

function scDashboardNotification($timeout) {
  return {
    scope: {
      message: '=',
      timeout: '=',
      customClass: '@?'
    },
    templateUrl: 'frs/shared/supporter/directives/scDashboardNotification/template',
    transclude: true,
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      if (attrs.addClass) {
        scope.customClass = attrs.addClass;
      }

      // Reset notification with correct message if there are multiple
      // attempts to submit something
      scope.$watch('message', function (newVal, oldVal) {
        if (newVal !== oldVal && !_.isEmpty(newVal)) {
          scope.display = true;

          // by default, set timeout on notification
          if (_.isUndefined(scope.timeout) || scope.timeout) {
            $timeout(function () {
              scope.display = false;
            }, 3000);
          }
        }
      }, true);

      scope.display = function () {
        if (scope.message.text !== '') {
          return true;
        }
        return false;
      };

      scope.dismiss = function () {
        scope.display = false;
        scope.message = {};
      };

      scope.notificationStyle = function () {
        var indigo = '#425CCD';
        var carnation = '#F77462';

        return {
          borderColor: scope.message.type === 'error' ? carnation : indigo
        };
      };
    }
  };
}
})();