(function(){
'use strict';

angular.module('classy.frs').controller('frsCtrl', ["$scope", "$state", "$stateParams", "$window", "$log", "$location", "EmbeddedGivingAnalytics", "EmbeddedGivingUtil", "scAnalyticsHelper", "scAuth", "scFundraisingPagesService", "scFundraisingTeamsService", "scThemesService", "scCampaignsService", "scMembersService", "scOrganizationsService", "scInlineStyles", "scAnalytics", "scAnalyticsServiceSettings", function ($scope, $state, $stateParams, $window, $log, $location, EmbeddedGivingAnalytics, EmbeddedGivingUtil, scAnalyticsHelper, scAuth, scFundraisingPagesService, scFundraisingTeamsService, scThemesService, scCampaignsService, scMembersService, scOrganizationsService, scInlineStyles, scAnalytics, scAnalyticsServiceSettings) {
  // Donation and Thank you pages cannot read $state.params.preview
  $scope.previewMode = !!($state.params.preview || SC.preview);

  /* Services to $scope */
  $scope.scInlineStyles = scInlineStyles;
  $scope.$stateParams = $stateParams;

  $scope.members = scMembersService;
  $scope.you = scMembersService.you;

  $scope.$watch(function () {
    return scMembersService.you;
  }, function () {
    $scope.you = scMembersService.you;
  });

  $scope.organization = scOrganizationsService.active;
  $scope.theme = scThemesService.active.current;
  $scope.campaign = scCampaignsService.active.current;
  $scope.fundraiser = scFundraisingPagesService.active;

  // if EG campaign, wire up EG analytics
  if (scCampaignsService.active.hasEmbeddedGiving()) {
    EmbeddedGivingAnalytics.init(scCampaignsService.active);
  }
  /* scAnalytics Setup */
  scAnalytics.registerSuperProperties();
  scAnalyticsServiceSettings.init(SC.analyticsServiceSettings);
  $scope.onDashboard = function () {
    return $state.current.name.indexOf('dashboard') > -1;
  };
  scAnalyticsHelper.setup($scope);
  scAnalytics.registerPageViewCallback(function () {
    // We want to remove the is_new query param after the page view has been
    // tracked so that we don't get erroneous page view counts in GA
    if ($location.search().is_new) {
      $state.go($state.$current.name, { is_new: null }, { notify: false });
    }
  });

  /* Conditionally initialize Heap
      - if not in an iframe, then immediately initialize
      - if in an iframe, then wait for EG's SDK open event 
      - TODO: we should do the same logic for Optimizely, 
        but I didn't want to increase testing efforts for this task */
  if (SC.heapId && scCampaignsService.active && !EmbeddedGivingUtil.isInIframe()) {
    scAnalytics.initHeap();
  }

  /* Conditionally initialize Optimizely (if has org tag and not in iframe) */
  // Patched this on CL-26325 per instruction of Ryan Szot
  if (scOrganizationsService.getTag('early-access:ab-testing', true) && scCampaignsService.active && !EmbeddedGivingUtil.isInIframe()) {
    scAnalytics.initOptimizely();
  }

  // eslint-disable-next-line no-unused-vars
  $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
    if (toState.name && fromState.name && (!(toState.name.indexOf(fromState.name) > -1 || fromState.name.indexOf(toState.name) > -1) || fromState.name == 'frs.team.index' && toState.name == 'frs.team.index')) {
      // don't scroll during sub view changes or if coming from an abstract state
      $window.scrollTo(0, 0);
    }

    /**
     * Check SSO status
     */
    scAuth.checkStatus().then(function () {
      $scope.$broadcast('memberChecked');
    }).catch(function () {});

    // Hide the block contents in bakcground while viewing fundraiser dashboard
    if ($scope.onDashboard()) {
      $scope.truncateForDashboard = { hide: true };
    } else {
      $scope.truncateForDashboard = { hide: false };
    }
  });

  $scope.memberPagesPromise = scMembersService.getAllMemberPages(scMembersService.you.current.id, scCampaignsService.active.current.id).then(function (resp) {
    _.forEach(resp.fundraisingPages, function (page) {
      scFundraisingPagesService.add(page);
      $scope.pages = scFundraisingPagesService;

      // add the team associated with the fundraising page
      if (page.fundraising_team_id) {
        scFundraisingTeamsService.fetch(page.fundraising_team_id);
      }
    });

    // getAllMemberPages only grabs teams that the member is a team lead for (aka created the team)
    _.forEach(resp.fundraisingTeams, function (team) {
      scFundraisingTeamsService.add(team);
      $scope.team = scFundraisingTeamsService;
    });
  });

  $scope.scAuth = scAuth;

  // Added for [FRS-7447]
  $log.log('Classy is looking to hire developers like you! To learn more visit: (https://www.classy.org/careers)');
}]);
})();