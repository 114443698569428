(function(){
'use strict';

frsSupporterRoutesConfig.$inject = ["$stateProvider"];
angular.module('classy.frs').config(frsSupporterRoutesConfig);

function frsSupporterRoutesConfig($stateProvider) {
  $stateProvider
  /**
   * Fundraiser Dashboard
   */
  .state('frs.fundraiser.dashboardindex', {
    url: '',
    abstract: true,
    views: {
      'body@frs.fundraiser': {
        templateUrl: 'frs/shared/supporter/template',
        controller: 'scSupporterAdminCtrl'
      }
    },
    resolve: {
      access: ["$stateParams", "checkDashboardAccess", "scPageAccessService", "scAuth", "scMembersService", function access($stateParams, checkDashboardAccess, scPageAccessService, scAuth, scMembersService) {
        if (_.get(scMembersService, 'active.current.id', false)) {
          return checkDashboardAccess($stateParams);
        }
        return scAuth.checkStatus().then(function () {
          return scPageAccessService.sync().then(function () {
            return checkDashboardAccess($stateParams);
          });
        });
      }]
    }
  }).state('frs.fundraiser.dashboardindex.dashboard', {
    abstract: true,
    url: '/dashboard',
    controller: 'scSupporterAdminCtrl'
  }).state('frs.fundraiser.dashboardindex.dashboard.overview', {
    url: '/overview',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/overview/template',
        controller: 'scSupporterOverviewCtrl'
      }
    },
    metaTags: {
      title: 'Fundraiser Dashboard'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.donations', {
    url: '/donations',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/donations/template',
        controller: 'scSupporterDonationsCtrl'
      }
    },
    metaTags: {
      title: 'Donations'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.updates', {
    abstract: true,
    url: '/updates',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        template: '<ui-view></ui-view>',
        controller: 'dashboardUpdatesCtrl'
      }
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.updates.manage', {
    url: '',
    templateUrl: 'frs/shared/supporter/pages/updates/template',
    controller: 'dashboardUpdatesCtrl',
    metaTags: {
      title: 'Updates'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.updates.create', {
    url: '/create',
    templateUrl: 'frs/shared/supporter/pages/updates/create/template',
    controller: 'dashboardUpdatesCreateCtrl',
    metaTags: {
      title: 'Create Update'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.updates.edit', {
    url: '/edit/:postId',
    templateUrl: 'frs/shared/supporter/pages/updates/edit/template',
    controller: 'dashboardUpdatesEditCtrl',
    metaTags: {
      title: 'Edit Update'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.updates.edit-story', {
    url: '/edit-story/:storyId',
    templateUrl: 'frs/shared/supporter/pages/updates/edit-story/template',
    controller: 'dashboardStoryEditCtrl',
    metaTags: {
      title: 'Edit Story'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.updates.preview', {
    url: '/preview/:postId',
    templateUrl: 'frs/shared/supporter/pages/updates/preview/template',
    controller: 'dashboardUpdatesPreviewCtrl',
    resolve: {
      resolvedPost: ['$stateParams', 'scUpdatesService', function ($stateParams, scUpdatesService) {
        return scUpdatesService.get($stateParams.postId);
      }]
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.emails', {
    abstract: true,
    url: '/emails',
    template: '<ui-view></ui-view>',
    controller: 'scSupporterEmailCtrl'
  }).state('frs.fundraiser.dashboardindex.dashboard.emails.template', {
    url: '',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/emails/template/template',
        controller: 'scSupporterEmailCtrl'
      }
    },
    metaTags: {
      title: 'Pick Template'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.emails.email', {
    url: '/:type',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/emails/email/template',
        controller: 'scSupporterEmailEmailCtrl'
      }
    },
    metaTags: {
      title: 'Email'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.details', {
    url: '/details',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/details/template',
        controller: 'fundraisingPageDetailsCtrl'
      }
    },
    metaTags: {
      title: 'Page Details'
    }
  }).state('frs.fundraiser.dashboardindex.dashboard.facebook', {
    url: '/facebook',
    views: {
      'supporter-body@frs.fundraiser.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/facebook/template',
        controller: 'facebookFundraiserCtrl'
      }
    },
    metaTags: {
      title: 'Facebook'
    }
  }).state('frs.team.dashboardindex', {
    url: '',
    abstract: true,
    views: {
      'body@frs.team': {
        templateUrl: 'frs/shared/supporter/template',
        controller: 'scSupporterAdminCtrl'
      }
    },
    resolve: {
      access: ["$stateParams", "checkDashboardAccess", "scPageAccessService", "scAuth", "scMembersService", function access($stateParams, checkDashboardAccess, scPageAccessService, scAuth, scMembersService) {
        if (_.get(scMembersService, 'active.current.id', false)) {
          return checkDashboardAccess($stateParams);
        }
        return scAuth.checkStatus().then(function () {
          return scPageAccessService.sync().then(function () {
            return checkDashboardAccess($stateParams);
          });
        });
      }]
    }
  })
  /**
   * Team Dashboard
   */
  .state('frs.team.dashboardindex.dashboard', {
    abstract: true,
    url: '/dashboard',
    controller: 'scSupporterAdminCtrl'
  }).state('frs.team.dashboardindex.dashboard.overview', {
    url: '/overview',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/overview/template',
        controller: 'scSupporterOverviewCtrl'
      }
    },
    metaTags: {
      title: 'Team Dashboard'
    }
  }).state('frs.team.dashboardindex.dashboard.donations', {
    url: '/donations',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/donations/template',
        controller: 'scSupporterDonationsCtrl'
      }
    },
    metaTags: {
      title: 'Donations'
    }
  }).state('frs.team.dashboardindex.dashboard.updates', {
    abstract: true,
    url: '/updates',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        template: '<ui-view></ui-view>',
        controller: 'dashboardUpdatesCtrl'
      }
    }
  }).state('frs.team.dashboardindex.dashboard.updates.manage', {
    url: '',
    templateUrl: 'frs/shared/supporter/pages/updates/template',
    metaTags: {
      title: 'Updates'
    }
  }).state('frs.team.dashboardindex.dashboard.updates.create', {
    url: '/create',
    templateUrl: 'frs/shared/supporter/pages/updates/create/template',
    controller: 'dashboardUpdatesCreateCtrl',
    metaTags: {
      title: 'Create Update'
    }
  }).state('frs.team.dashboardindex.dashboard.updates.edit', {
    url: '/edit/:postId',
    templateUrl: 'frs/shared/supporter/pages/updates/edit/template',
    controller: 'dashboardUpdatesEditCtrl',
    metaTags: {
      title: 'Edit Update'
    }
  }).state('frs.team.dashboardindex.dashboard.updates.edit-story', {
    url: '/edit-story/:storyId',
    templateUrl: 'frs/shared/supporter/pages/updates/edit-story/template',
    controller: 'dashboardStoryEditCtrl',
    metaTags: {
      title: 'Edit Story'
    }
  }).state('frs.team.dashboardindex.dashboard.updates.preview', {
    url: '/preview/:postId',
    templateUrl: 'frs/shared/supporter/pages/updates/preview/template',
    controller: 'dashboardUpdatesPreviewCtrl',
    resolve: {
      resolvedPost: ['$stateParams', 'scUpdatesService', function ($stateParams, scUpdatesService) {
        return scUpdatesService.get($stateParams.postId);
      }]
    }
  }).state('frs.team.dashboardindex.dashboard.emails', {
    abstract: true,
    url: '/emails',
    template: '<ui-view></ui-view>',
    controller: 'scSupporterEmailCtrl'
  }).state('frs.team.dashboardindex.dashboard.emails.template', {
    url: '',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/emails/template/template',
        controller: 'dashboardUpdatesCtrl'
      }
    },
    metaTags: {
      title: 'Pick Template'
    }
  }).state('frs.team.dashboardindex.dashboard.emails.email', {
    url: '/:type',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/emails/email/template',
        controller: 'scSupporterEmailEmailCtrl'
      }
    },
    metaTags: {
      title: 'Email'
    }
  }).state('frs.team.dashboardindex.dashboard.teammates', {
    url: '/teammates',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/teammates/template',
        controller: 'teammatesCtrl'
      }
    },
    metaTags: {
      title: 'Teammates'
    }
  }).state('frs.team.dashboardindex.dashboard.details', {
    url: '/details',
    views: {
      'supporter-body@frs.team.dashboardindex': {
        templateUrl: 'frs/shared/supporter/pages/details/template',
        controller: 'fundraisingTeamDetailsCtrl'
      }
    },
    metaTags: {
      title: 'Team Details'
    }
  });
}
})();