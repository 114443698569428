(function(){
'use strict';

/**
 * @kind run
 * @name page-data
 *
 * @description
 * A run block to instantiate the fieldOptions for the crowdfunding campaign setup.
 */
supporterFundraisingPageFormData.$inject = ["scPagesService"];
angular.module('classy.frs').run(supporterFundraisingPageFormData);

function supporterFundraisingPageFormData(scPagesService) {
  var fieldOptions = function fieldOptions(isTribute) {
    var label = isTribute ? 'Picture of Honoree' : 'Your Picture';
    return {
      photo: {
        model: 'fundraisingPage.logo',
        previewWidth: 195,
        previewHeight: 195,
        width: 300,
        height: 300,
        label: label,
        buttonText: 'Upload',
        format_text: 'For best quality, use a 300 x 300 px image',
        labelInline: true,
        buttonStyle: 'submitButtonStyle()',
        hasRemoveButton: '!hideRemoveButton',
        removeButtonStyle: 'removeButtonStyle()',
        removeButtonText: 'Remove',
        removeButtonAction: 'removeButtonAction()',
        nullImage: "'/static/global/images/higher_contrast_individual_default_image.png'"
      }
    };
  };

  var pageData = {
    scope: {
      fieldOptions: fieldOptions
    },
    init: _.noop
  };

  scPagesService.add('supporter', 'details-fundraising-page', pageData);
}
})();