(function(){
'use strict';

checkDashboardAccessFactory.$inject = ["$q", "$state", "$timeout", "scFundraisingPagesService", "scFundraisingTeamsService"];
angular.module('classy.frs').factory('checkDashboardAccess', checkDashboardAccessFactory);

function checkDashboardAccessFactory($q, $state, $timeout, scFundraisingPagesService, scFundraisingTeamsService) {
  return function checkAccess(params) {
    var type = params.fundraisingPageId ? 'fundraiser' : 'team';

    var deferred = $q.defer();

    function resolveAccess(hasAccess) {
      if (hasAccess) {
        deferred.resolve();
      } else {
        deferred.reject();
      }
      if (!hasAccess) {
        $timeout(function () {
          $state.go('frs.' + type + '.index', params);
        });
      }
    }

    switch (type) {
      case 'fundraiser':
        scFundraisingPagesService.active.checkDashboardAccess().then(function (response) {
          resolveAccess(response);
        });
        break;
      case 'team':
        scFundraisingTeamsService.active.checkDashboardAccess().then(function (response) {
          resolveAccess(response);
        });
        break;
      default:
        resolveAccess(false);
    }

    return deferred.promise;
  };
}
})();