(function(){
'use strict';

dashboardUpdatesPreviewCtrl.$inject = ["$scope", "$stateParams", "scFacebook", "resolvedPost", "scPostListsService"];
angular.module('classy.frs').controller('dashboardUpdatesPreviewCtrl', dashboardUpdatesPreviewCtrl);

function dashboardUpdatesPreviewCtrl($scope, $stateParams, scFacebook, resolvedPost, scPostListsService) {
  $scope.post = resolvedPost;
  $scope.postIndex = null;
  $scope.updatePage = $scope.$parent.updatePage;

  var postList = void 0;
  scPostListsService.queryPosts($scope.$parent.getPostsEndpoint).then(function (response) {
    postList = response;
    $scope.postIndex = scPostListsService.getPostNumber($stateParams.postId, postList);
  });

  $scope.isDomainMasked = false;
  /* ---------------------------- ------------------------------------------ *
   * Added Condition to check whether it is Domain Masked or not
   *---------------------------------------------------------------------- */
  if (SC.organization.domain_mask) {
    $scope.isDomainMasked = true;
  }

  $scope.fbShare = function (postId) {
    // strip HTML tags
    var fbShareTitle = $scope.post.title.replace(/(<([^>]+)>)/gi, '');
    var fbSharePost = $scope.post.body.replace(/(<([^>]+)>)/gi, '');

    // if the post has imgs, by default grab the first img
    var fbShareImg = $scope.post.body.match(/<img src="([^">]+)/gi);
    if (fbShareImg) {
      fbShareImg = fbShareImg[0].substring(10, fbShareImg[0].length);
    } else {
      fbShareImg = '';
    }

    scFacebook.postToFeed({
      link: $scope.shareLink + postId,
      name: fbShareTitle,
      description: fbSharePost,
      picture: fbShareImg
    });
  };
}
})();