(function(){
'use strict';

scSupporterEmailEmailCtrl.$inject = ["$scope", "$state", "recipientPickerFlow", "scFlowModalService", "scMembersService", "scOrganizationsService", "scFundraiserDashboardEmailService", "scPagesService", "scUserAgent"];
angular.module('classy.frs').controller('scSupporterEmailEmailCtrl', scSupporterEmailEmailCtrl);

function scSupporterEmailEmailCtrl($scope, $state, recipientPickerFlow, scFlowModalService, scMembersService, scOrganizationsService, scFundraiserDashboardEmailService, scPagesService, scUserAgent) {
  $scope.isFocused = false;

  $scope.focused = function () {
    $scope.isFocused = true;
  };

  $scope.blurred = function () {
    $scope.isFocused = false;
  };

  $scope.triggerHelp = function () {
    scFlowModalService.open({
      templateUrl: 'frs/shared/supporter/pages/emails/email/modalHelp',
      context: $scope,
      maxWidth: 450
    }, {
      animate: true
    });
  };

  $scope.tooltipPosition = { my: 'bottom center', at: 'top center' };

  function getMessageCopy(type, fundraiser) {
    var orgName = scOrganizationsService.active.current.name;

    if (type == 'appeal') {
      $scope.title = 'Ask for donations';
      $scope.subject = 'Help me support ' + orgName;
      $scope.subTitle = 'Email your closest friends and family to start, then send an email to everyone you know.';
      $scope.linkText = 'Donate to my page';
    } else if (type == 'invite') {
      $scope.subject = 'Join my team and support ' + orgName;
      $scope.title = 'Invite Teammates';
      $scope.subTitle = 'Team fundraising works best when you have lots of members fundraising towards a goal. Invite new members.';
      $scope.linkText = 'Join my team';
    } else {
      $scope.title = 'Thank your donors';
      $scope.subject = 'Thanks for supporting ' + orgName;
      $scope.subTitle = 'Thanking donors with an email goes a long way, but we also recommend you comment on their donation publicly.';
      $scope.linkText = 'View my page';
    }

    if (fundraiser) {
      $scope.url = scPagesService.getUrl({
        pageType: 'fundraiser',
        includeBase: true
      }).url;
    } else {
      $scope.url = scPagesService.getUrl({
        pageType: 'team',
        includeBase: true
      }).url;
    }

    function newLines(input) {
      var regex = /<br[^>]*>/gi;
      return input.replace(regex, '\r\n');
    }

    var emailBody = encodeURIComponent(newLines($scope.message.text + '\n\n' + $scope.from.first_name + '\n' + $scope.url));
    $scope.mailToLink = 'mailto:?subject=' + encodeURIComponent($scope.subject) + '&body=' + emailBody;
  }
  $scope.from = scMembersService.active.current;
  $scope.type = $state.params.type;

  $scope.message = {};
  var message = void 0;
  var fundraiserDashboard = $state.current.name.indexOf('fundraiser') > -1;

  if ($scope.type == 'appeal') {
    message = fundraiserDashboard ? $scope.campaign.default_page_appeal_email : $scope.campaign.default_team_appeal_email;
  } else if ($scope.type != 'invite') {
    message = fundraiserDashboard ? $scope.campaign.default_page_thank_you_email : $scope.campaign.default_team_thank_you_email;
  }

  if (!message) {
    message = scFundraiserDashboardEmailService.getDefaultMessageCopy($scope.type, fundraiserDashboard);
  }

  $scope.message.text = message;

  getMessageCopy($scope.type, fundraiserDashboard);

  $scope.openDonors = function () {
    recipientPickerFlow();
  };

  $scope.mailto = scUserAgent.isMobile();

  $scope.copiedEmailStyle = function () {
    var focusedBorder = { 'border-color': $scope.theme.styles.primaryColor };
    return $scope.isFocused ? focusedBorder : {};
  };

  $scope.primaryColorFill = function () {
    return {
      'background-color': $scope.theme.styles.primaryColor,
      'border-color': $scope.theme.styles.primaryColor
    };
  };
}
})();