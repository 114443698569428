(function(){
'use strict';

dashboardUpdatesEditCtrl.$inject = ["$rootScope", "$scope", "$http", "$stateParams", "scPostListsService"];
angular.module('classy.frs').controller('dashboardUpdatesEditCtrl', dashboardUpdatesEditCtrl);

function dashboardUpdatesEditCtrl($rootScope, $scope, $http, $stateParams, scPostListsService) {
  $scope.loading = true;
  $scope.post = {
    title: '',
    body: ''
  };
  $scope.postIndex = null;

  var postList = void 0;
  scPostListsService.queryPosts($scope.$parent.getPostsEndpoint).then(function (response) {
    postList = response;
    $scope.postIndex = scPostListsService.getPostNumber($stateParams.postId, postList);
  });

  $http.get('/frs-api/updates/' + $stateParams.postId).then(function (response) {
    $scope.loading = false;
    $scope.post = {
      title: response.data.title,
      body: response.data.body
    };
  }, function (err) {
    $scope.loading = false;

    var errMsg = 'Sorry, something went wrong. You will be redirected back to your updates...';
    if (err.status == 404) {
      errMsg = 'Sorry, this post could not be found. You will be redirected back to your';
    }

    $rootScope.SC.status.banner = {
      type: 'error',
      msg: errMsg
    };

    // Redirect user to updates page
    setTimeout(function () {
      $scope.goTo('updates.manage');
    }, 5000);
  });

  $scope.$watch('post', function (newVal) {
    if (newVal.title.length > 0 || newVal.body.length > 0) {
      $scope.displayDelete = true;
    } else {
      $scope.displayDelete = false;
    }
  }, true);

  $scope.submit = function () {
    $http.put('/frs-api/updates/' + $stateParams.postId, $scope.post).then(function () {
      $rootScope.SC.status.banner = {
        type: 'success',
        msg: 'Your post has been updated!'
      };

      // Redirect user to updates page
      setTimeout(function () {
        $scope.goTo('updates.manage');
      }, 5000);
    }, function () {
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Sorry, this is not yet available.'
      };
    });
  };
}
})();