(function(){
'use strict';

DonationsCollectionFactory.$inject = ["$http"];
angular.module('classy.frs').factory('DonationsCollection', DonationsCollectionFactory);

function DonationsCollectionFactory($http) {
  /**
   * Putting that computer science degree to use.
   */
  function DonationsCollection(model, me, isTeam) {
    this.resource = isTeam ? 'fundraising-teams' : 'fundraising-pages';

    this.me = me;
    this.dateOrder = 'desc';
    this.unacknowledgedQueue = [];
    this.model = model;
    this.page = 0;
  }

  DonationsCollection.prototype.get = function tempGet(sorter) {
    this.page += 1;
    this.dateOrder = sorter;
    return $http({
      url: '/frs-api/' + this.resource + '/' + this.model.id + '/feed-item-donations',
      params: _.assign({ page: this.page }, {
        filter: 'linkable_type*=donation',
        with: 'linkable',
        sort: 'created_at:' + this.dateOrder,
        per_page: 10,
        member_id: this.me.id
      }),
      method: 'GET'
    });
  };

  DonationsCollection.prototype.resetPage = function pageReset() {
    this.page = 0;
  };

  return DonationsCollection;
}
})();